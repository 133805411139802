import React from 'react';
import "../css/Services.css";
import Navigation from './Navigation';

import image1 from '../assets/hero-services.jpg';
import image2 from '../assets/body-services.jpg';

const Services = () => {
   return (
      <>
      <Navigation />
      <div>
         <img class="heroImageServices" src={image1} alt="horse"></img>
         <div class="mainTextBodyHeaderServices">Ryebridge Stud Services</div>
         <div class="row">
            <div class="column">
               <div class="horseInfoTitle">BOARDING MARES</div>
               <div class="horseInfoText">At Ryebridge Stud, we offer both seasonal and full-time boarding for mares on both a national and international level. For entries of overseas mares, we use our isolation boxes, located separate to the main yards so as to ensure our high biosecurity levels are maintained.</div>
               <div class="horseInfoTitle">SALES PREPARATION</div>
               <div class="horseInfoText">One of our key focuses throughout each year is in preparing consignments for various sales. We pride ourselves on having a team who not only spend one on one time with each horse daily, but by being well experienced in this area, can identify the individual needs of each horse. In doing so, we alter training, diet and routine as needs be so as to ensure the most accurate preparation for the individual sale. It is our core belief that understanding the training needs of the horse and its abilities, is what allows us to confidently advise the owner on the most appropriate market for the horse to reach its full potential.</div>
               <div class="horseInfoTitle">SPELLING</div>
               <div class="horseInfoText">With varying sized, secure and safe paddocks, we offer the perfect facilities for racehorses to come and take a well-deserved break or if required, rehab.</div>
               <div class="horseInfoTitle">SCHOOLING OF SPORT HORSES</div>
               <div class="horseInfoText">With years of experience in breeding, breaking and training our own sport horses, we would be happy to welcome your horse or both yourself and your horse for schooling and or coaching. Training provided is focused on top level show jumping and the adhering flatwork. We have a high level of knowledge in training young horses and ride in a gentle and efficient manner. With both Sebastian and Thea still competing on a regular basis, we assure that all schooling and coaching offered is up to date and relevant with current standards.</div>
               <div class="horseInfoText">Should you wish to know more about the services we provide we would love to hear from you: <a class="subSectionText" href="mailto:info@ryebridgestud.com">info@ryebridgestud.com</a></div>

            </div>

            <div class="column">

               <img src={image2} width="100%" height="100%" alt="horse" />
            </div>
         </div>
      </div>
      </>
   );
}

export default Services;