import React from 'react';
import { useRef } from 'react';


import "../css/Header.css";
import "../css/fonts.css";
import "../css/Wedding.css";
import NavigationWedding from './NavigationWedding';

import image1 from '../assets/one.png';
import image2 from '../assets/two.png';
import image3 from '../assets/three.png';
import image4 from '../assets/four.png';
import image5 from '../assets/five.png';
import image6 from '../assets/six.png';
import image7 from '../assets/seven.png';
import image8 from '../assets/eight.png';
import image9 from '../assets/nine.png';

const Wedding = () => {
   return (
       <>
       <div class="weddingPage">

     
       <NavigationWedding/>


       <div id="heading"><img class="weddingImages imageOne"  src={image1} alt="wedding logo" /></div>
       <div id="nothing-fancy"><img class="weddingImages"  src={image2} alt="wedding logo" /></div>
       <div id="the-wedding-day"><img class="weddingImages"  src={image3} alt="wedding logo" /></div>
       <div id="note"><img class="weddingImages"  src={image4} alt="wedding logo" /></div>
       <div id="cute-pics-two"><img class="weddingImages"  src={image5} alt="wedding logo" /></div>
       <div id="dress-code"><img class="weddingImages"  src={image6} alt="wedding logo" /></div>
       <div id="travel-stay"><img class="weddingImages"  src={image7} alt="wedding logo" /></div>
       <div id="cute-pics-two"><img class="weddingImages"  src={image8} alt="wedding logo" /></div>
       <a href="https://forms.gle/JdL9XfU1fMrDRvVe7" target="noopener noreferrer">
         <div id="rsvp"><img class="weddingImages"  src={image9} alt="wedding logo" /></div>
      </a>
       </div>
</>
   );
}

export default Wedding;