import React from 'react';

import "../css/Footer.css";
import image1 from '../assets/ryebridge-logo-alt.svg';
import image2 from '../assets/instagram.svg';
import image3 from '../assets/facebook.svg';
import image4 from '../assets/email.svg';


const Navigation = () => {
    return (
        <div class="footer">
            <div class="footerContainer">
                <div class="mainLogoFooter">
                    <img src={image1} width="145px" height="30px" alt="horse" />
                </div>
                <div class="mainSocialFooter">
                    <a href="https://www.instagram.com/ryebridgestud/" target="noopener noreferrer">
                        <img class="mainSocialFooter" src={image2} alt="horse" />
                    </a>
                    <a href="https://www.facebook.com/ryebridgestud" target="noopener noreferrer">
                        <img class="mainSocialFooter" src={image3} alt="horse" />
                    </a>
                    <a href="mailto:info@ryebridgestud.com" target="noopener noreferrer">
                        <img class="mainSocialFooter" src={image4} alt="horse" />
                    </a>
                </div>
                <div class="copyText">© Ryebridge Stud 2020. All rights reserved.</div>
            </div>
        </div>

    );
}

export default Navigation;