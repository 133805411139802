import React from 'react';

import { NavLink } from 'react-router-dom';
import image1 from '../assets/ryebridge-logo.svg';
import "../css/Header.css";
import "../css/fonts.css";


const Navigation = () => {
    return (
        <div class="header">
            <a href="/" class="logo">
                <img class="mainLogo" src={image1} alt="horse" />
            </a>
            <div class="header-right">
                <NavLink class="linkHeader" to="/Graduates">Graduates</NavLink>
                {/* <NavLink class="linkHeader" to="/Sales">Sales</NavLink> */}
                <NavLink class="linkHeader" to="/Facilities">Facilities</NavLink>
                <NavLink class="linkHeader" to="/Careers">Careers</NavLink>
                <NavLink class="linkHeader" to="/Services">Services</NavLink>
                <NavLink class="linkHeader" to="/Gallery">Gallery</NavLink>
                <NavLink class="linkHeader" to="/Contact">Contact</NavLink>
            </div>
        </div>

    );
}

export default Navigation;


