import React from 'react';

import "../css/Gallery.css";

import Navigation from './Navigation';

import image1 from '../assets/hero-gallery.jpg';
import image2 from '../assets/Gallery1.jpg';
import image4 from '../assets/Gallery3.jpg';
import image5 from '../assets/Gallery4.jpg';
import image6 from '../assets/Gallery5.jpg';
import image7 from '../assets/Gallery6.jpg';
import image8 from '../assets/Gallery7.jpg';
import image9 from '../assets/Gallery8.jpg';
import image10 from '../assets/Gallery9.jpg';
import image11 from '../assets/Gallery10.jpg';
import image12 from '../assets/Gallery11.jpg';
import image13 from '../assets/Gallery12.jpg';
import image14 from '../assets/Gallery13.jpg';
import image15 from '../assets/Gallery14.jpg';
import image16 from '../assets/Gallery15.jpg';
import image17 from '../assets/Gallery16.jpg';
import image18 from '../assets/Gallery17.jpg';
import image19 from '../assets/Gallery18.jpg';
import image20 from '../assets/Gallery19.jpg';
import image21 from '../assets/Gallery20.jpg';
import image22 from '../assets/Gallery2.jpg';

const Gallery = () => {
   return (
      <>
      <Navigation />
      <div>
         <img class="heroImageServices" src={image1} alt="horse"></img>
         <div class="mainTextBodyHeaderServices">Gallery</div>
         <div class="mainTextBodyHeaderGallery">
            <div class="gallerySubText">Follow us Instagram and Facebook for all the latest news and updates from Ryebridge Stud.</div>
            <div class="responsive">
               <div class="gallery">
                  <img src={image2} width="100%" alt="horse" />
               </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image4} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image5} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image6} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image7} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image8} width="100%" alt="horse" />

                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image9} width="100%" alt="horse" />

                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image10} width="100%" alt="horse" />

                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image11} width="100%" alt="horse" />

                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image12} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image13} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image14} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image15} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image16} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image17} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image18} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image19} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image20} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image21} width="100%" alt="horse" />
                  </div>
               </div>
               <div class="responsive">
                  <div class="gallery">
                     <img src={image22} width="100%" alt="horse" />
                  </div>
               </div>
               
      </div>
      </div>
      </>
   );
}

export default Gallery; 