import React from 'react';

import Navigation from './Navigation';

import image1 from '../assets/hero-facilities.jpg';
import image2 from '../assets/body-facilities.jpg';

const Facilities = () => {
    return (
        <>
        <Navigation />
        <div>
            <img class="heroImageServices" src={image1} alt="horse"></img>
            <div class="mainTextBodyHeaderServices">Ryebridge Stud Facilities</div>
            <div class="row">
                <div class="column">
                    <div class="horseInfoTitle">GRAZING</div>
                    <div class="horseInfoText">We have over 100 acres of well-maintained, stud railed paddocks that we use for grazing. There are paddocks of all different sizes to suit the requirements of the horses. All of the paddocks are naturally sheltered with hedgerows.</div>
                    <div class="horseInfoTitle">ALL WEATHER TURNOUT</div>
                    <div class="horseInfoText">There are four all weather sand paddocks which allow for the horses to spend time outside all year round in a safe environment.</div>
                    <div class="horseInfoTitle">STABLING</div>
                    <div class="horseInfoText">There are two yards here at Ryebridge stud. The white yard, which is the main yard, consists of twenty-two stables, split into three blocks. In the red yard there are twenty-eight stables. Due to its proximity to our exercise facilities, this yard is mainly used for young stock and any horses being prepped for sales. Additionally, there is an isolation unit complete with a separate turn out. This unit has the option of a separate entrance where complete isolation for a horse is necessary.</div>
                    <div class="horseInfoTitle">EXERCISE FACILITIES</div>
                    <div class="horseInfoText">
                        We have numerous facilities that allow us to ensure adequate exercise for all horses despite the unpredictable Irish weather, including:<br></br>
                        • A covered six horse electric walker. The quietness of the electric walker is excellent, especially for the young stock.<br></br>
                        • A covered lunge pen, 17m in diameter, fitted out with lights which allow for year-round use.<br></br>
                        • A sand and fiber-based exercise path used for safe, in hand walking of foals who are being prepped for sales.<br></br>
                        • A flood lit, outdoor, sand and fiber arena with a large variety of high-quality show jumping fences.<br></br>
                    </div>
                    <div class="horseInfoTitle">LOADING BAY</div>
                    <div class="horseInfoText">This allows for safe and secure loading of the horses.</div>
                    <div class="horseInfoTitle">VET STOCKS</div>
                    <div class="horseInfoText">We have a fully padded stock with a safely enclosed area in front which allows foals to accompany the mare.</div>
                    <div class="horseInfoText">For more information about our facilities just get in touch: <a class="subSectionText" href="mailto:info@ryebridgestud.com">info@ryebridgestud.com</a></div>

                </div>

                <div class="column">

                    <img src={image2} width="100%" height="100%" alt="horse" />
                </div>
            </div>
        </div>
        </>
    );
}

export default Facilities;