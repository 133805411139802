import React from 'react';
import "../css/Home.css";
import image2 from '../assets/hero-home.jpg';
import image4 from '../assets/himalayan-beauty-news.png';
import image5 from '../assets/Article04.jpg';
import image6 from '../assets/Article03.jpg';

import Navigation from './Navigation';


const Home = () => {
    return (
        <>
        <Navigation />
        <div class="container">
            <img class="heroImage" src={image2} width="600" height="400"  alt="horse" />
            <div class="mainTextBodyHome">
                <div class="mainTextBodyHeader">OUR STORY</div>
                <div class="subSectionText">Ryebridge Stud is a 176 acre stud farm located in Kilcock, Co Meath. In a county renowned for horse breeding, training and racing, Ryebridge Stud has set itself amongst some of the very best. The Stud is owned and run by the Curran family. Between them, the Curran family have decades of experience in the equine industry and each bring their own set of expertise. The family remain active on the breeding, racing and sport horse fronts of the industry. At Ryebridge Stud we find that our highly-professional and experienced team, combined with excellent facilities provide the best possible environment for raising horses.</div>
                
                <div class="subSectionText">
                As breeders of numerous winners along with Black Type performing thoroughbreds and top level sport horses, we here at Ryebridge Stud are well versed in matching mares to stallions and are therefore skilled at bringing together successful mating plans.
                With the focus of so many being on the stallion, we at Ryebridge Stud like to ensure that the mare is not forgotten and we put her first and foremost. After all the mare is the essential link in the breeding and rearing of future equine athletes.
                Once the foal is born, we offer the appropriate care and individual attention required, combined with the ideal facilities and training to allow the horse the very best possible start to their career.
                Whether it’s a seasonal boarder, a foal for consignment or a race horse who is here for a well-deserved rest, every horse at Ryebridge Stud is treated like one of our very own. Each horse can be guaranteed to receive the very best of everything on offer here.
                The emphasis at Ryebridge Stud is always on personalized care and attention. Without a doubt, this brings out the best in the horses and is the top priority for our team.
                </div>
                <div class="subSectionText">
                Please enjoy our website and social media pages to find out more about us. If there is anything we can help you with please get in touch: <a class="subSectionText" href="mailto:info@ryebridgestud.com">info@ryebridgestud.com</a><br></br></div>
            </div>
            <div class="newsHeader">Latest News</div>
                <div class="row">
                    <div class="columnHome">
                    <div class='columnContainer'>
                            <a href="https://www.irishracing.com/news?headline=Cheekpieces-a-help-as-Beauty-sheds-maiden-tag-at-7th-attempt&prid=219849&prt=L" target="noopener noreferrer">
                                <img src={image4} width="100%" height="100%" alt="horse" />
                            </a>
                            <div class="horseNews">Cheek-pieces a help as Beauty sheds maiden tag at 7th attempt</div>
                        </div>
                    </div>

                    <div class="columnHome">
                        <div class='columnContainer'>
                            <a href="https://www.darleyeurope.com/news/20th-first-crop-winner-profitable" target="noopener noreferrer">
                                <img src={image6} width="100%" height="100%" alt="horse" />
                            </a>
                            <div class="horseNews">20th first crop winner for Profitable</div>
                        </div>
                    </div>

                    <div class="columnHome">
                    <div class='columnContainer'>
                            <a href="https://www.irishracing.com/news?headline=Farragher-keeps-it-and-quot-nice-and-simple-and-quot-and-Command-delivers&prid=215354#.YBR_xb1TejI.whatsapp" target="noopener noreferrer">
                                <img src={image5} width="100%" height="100%" alt="horse" />
                            </a>
                            <div class="horseNews">Farragher keeps it "nice and simple" and Command delivers</div>
                        </div>
                    </div>

                    
                </div>
                <a href="https://www.instagram.com/ryebridgestud/" class="newsLink" target="noopener noreferrer">
                    <div class="newsSubHeader">More News</div>
                </a>
            </div>
            </>
    );
}

export default Home;