import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Home from './components/Home';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Facilities from './components/Facilities';
import Careers from './components/Careers';
import Wedding from './components/Wedding';
import Contact from './components/Contact';
import Error from './components/Error';
import Navigation from './components/Navigation';
import Title from './components/Title';
// import Sales from './components/Sales';
import Footer from './components/Footer';
import Graduates from './components/Graduates';
import MeetTheTeam from './components/MeetTheTeam';
import './css/styles.css';
import './css/fonts.css';


 
class App extends Component {
  render() {
    return (      
       <BrowserRouter>
            <Switch>
             <Route path="/" component={Home} exact/>
             <Route path="/Services" component={Services}/>
             {/* <Route path="/Sales" component={Sales}/> */}
             <Route path="/Facilities" component={Facilities}/>
             <Route path="/Gallery" component={Gallery}/>
             <Route path="/Contact" component={Contact}/>
             <Route path="/Careers" component={Careers}/>
             <Route path="/Graduates" component={Graduates}/>
             <Route path="/Wedding" component={Wedding}/>
             <Route path="/MeetTheTeam" component={MeetTheTeam}/>
            <Route component={Error}/>
           </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}
 
export default App;