import React from 'react';

import image1 from '../assets/hero-contact.jpg';
import image2 from '../assets/body-contact.jpg';
import Navigation from './Navigation';

const Contact = () => {
    return (
        <>
        <Navigation />
        <div>
            <div className="row">
                <div className="column">
                <h1>Job Title: Live in Head Lad/Lass</h1>
                    <p><strong>Job Type:</strong> Full-time</p>
                    <p><strong>Location:</strong> Ryebridge Stud Co.Meath</p>

                    <h3>About Us:</h3>
                    <p>We are a small, family-run stud farm dedicated to breeding top-quality Thoroughbreds for sales and racing, as well as producing exceptional sport horses for showjumping. We are passionate about our horses and their welfare is of utmost importance to us. As a close knit and caring team, maintaining a good attitude on the yard is key in filling this role. </p>

                    <h3>Job Description:</h3>
                    <p>We are seeking an experienced and passionate Head Lad/Lass to work closely with our stud manager in managing the day-to-day operations of our stud farm.  </p>
                    <p>This role will entail in all aspects of stud work, from sales prep to representing us at the sales, overseeing all aspects of the breeding process as well as general farm maintenance. The ideal candidate will have a deep understanding of horse care, breeding, and the specific needs of both Thoroughbreds and sport horses.   </p>

                    <h3>Key Responsibilities:</h3>
                    <ul>
                        <li>Horse Care: Daily management of the stud's horses, including feeding, grooming, handling, and ensuring their overall well-being.</li>
                        <li>Breeding Operations: Assist with all aspects of the breeding program, including handling mares and foals, monitoring pregnant mares, foaling, and assisting with veterinary care as needed.</li>
                        <li>Sales Prep: Prepare horses for sales, including conditioning, grooming, and handling to ensure they are presented at their best.</li>
                        <li>Young Horse Development: Assist in the care and development of young horses, including groundwork and basic training for both Thoroughbreds and Sport horses.</li>
                        <li>Stable Management: lead the team in ensuring cleanliness of the stables, including mucking out, bedding down, and ensuring a high standard of hygiene is maintained.</li>
                        <li>Team Leadership: Supervise and work alongside a small team of staff, providing guidance, training, and support where necessary.</li>
                        <li>Record Keeping: Maintain accurate records of horse care, breeding, and health in accordance with industry standards.</li>
                        <li>Event Participation: Assist with the preparation and logistics for horse shows, sales, and racing events.</li>
                        <li><em>Please note; this position requires physical work.</em> </li>
                    </ul>


                    <h3>Qualifications and Experience:</h3>
                    <ul>
                    <li>Extensive experience working in a stud or similar equestrian environment.</li>
                    <li>Strong knowledge of Thoroughbred and Sport horse care, breeding, and sales preparation.</li>
                    <li>Experience with young horse handling is essential.</li>
                    <li>Ability to lead a small team, with strong communication skills.</li>
                    <li>Competent in basic farm maintenance and equipment operation.</li>
                    <li>Willingness to work flexible hours, including weekends and evenings as required.</li>
                    <li>A valid driving license is essential; experience transporting horses desirable. </li>
                    </ul>

                    <h3>Personal Attributes:</h3>
                    <ul>
                    <li>Passionate about horses and genuinely dedicated to their care and development.</li>
                    <li>Highly organized with a strong attention to detail.</li>
                    <li>Motivated and up for a good laugh!</li>
                    <li>Hands-on, proactive with a good attitude.</li>
                    <li>Resilient and adaptable to the unpredictable nature of working with horses!</li>
                    </ul>

                    <h3>What We Offer:</h3>
                    <ul>
                    <li>Salary will be based on experience and will be inclusive of accommodation.</li>
                    <li>The opportunity to work in a supportive and fun environment.</li>
                    <li>Involvement in a variety of equestrian disciplines, providing a diverse and fulfilling role.</li>
                    <li>Training in any areas required, there is always something to learn.</li>
                    </ul>

                    <h3>How to Apply:</h3>
                    <li>Please send your CV and a cover letter detailing why you are the ideal candidate for this role to  info@ryebridgestud.com</li>
                    <li>Applications in strict confidence. </li>

                    <br></br>
                        
                    <a target="_blank" href="https://forms.gle/xk979hY9T6iSCqK59">
                        <button class="glow-on-hover" target="_blank">Apply here</button>
                    </a>

                    <h1>Job Title: Yard Hand</h1>
                    <p><strong>Job Type:</strong> Full-time</p>
                    <p><strong>Location:</strong> Ryebridge Stud</p>

                    <h3>Job Description:</h3>
                    <p>Ryebridge Stud Farm is seeking a Yard Hand to join our team. The Yard Hand will be responsible for maintaining the cleanliness and organization of the stables and surrounding areas. This position requires a good work ethic, reliability, and attention to detail.</p>

                    <h3>Responsibilities:</h3>
                    <ul>
                        <li>Cleaning and maintaining the stables, tack rooms, and surrounding areas</li>
                        <li>Repairing and maintaining farm buildings, fences, and equipment</li>
                        <li>Operating and maintaining farm machinery, such as tractors and irrigation systems</li>
                        <li>Planting and horticultural maintence</li>
                        <li>Monitoring and controlling pests and diseases</li>
                        <li>Managing waste and environmental issues, such as manure disposal and erosion control</li>
                        <li>Monitoring and maintaining farm safety and security, such as ensuring proper storage and handling of chemicals and equipment, and protecting against theft or vandalism</li>
                        <li>Providing support to other farm workers and performing other duties as assigned by the farm manager or supervisor</li>
                    </ul>


                    <h3>Requirements:</h3>
                    <ul>
                        <li>Horse experience preferred but not essential</li>
                        <li>Good work ethic and reliability essential</li>
                        <li>Physically fit and able to perform manual labor tasks</li>
                        <li>Willingness to work flexible hours as needed</li>
                        <li>Strong attention to detail and ability to follow instructions</li>
                    </ul>

                    <p> Ryebridge Stud offers competitive wages, a supportive team environment, and opportunities for growth and development.</p>

                    <p>To apply, please submit your resume and cover letter highlighting your experience and qualifications. We thank all applicants for their interest, but only those selected for an interview will be contacted.</p>
                        
                    <a target="_blank" href="https://forms.gle/xk979hY9T6iSCqK59">
                        <button class="glow-on-hover" target="_blank">Apply here</button>
                    </a>  
                </div>

                <div className="column">

                    <img src={image2} width="100%" height="100%" alt="horse" />
                </div>
            </div>
        </div>
        </>
    );
}

export default Contact;