import React from 'react';
import "../css/Services.css";
import "../css/Graduates.css";

import image1 from '../assets/foals-hero.png';
import bellam from '../assets/bellam-1.png';
import jamesboru from '../assets/james-boru.png';
import kingarthur from '../assets/king-arthur.png';
import massini from '../assets/massini.png';
import turbocommand from '../assets/turbo-command-2017.png';
import turbo from '../assets/turbo.png';
import himalayan from '../assets/himalayan-beauty.png';
import mythicalmolly from '../assets/mythical-molly.png';
import beauxvillage from '../assets/beaux-villages.jpeg';
import Navigation from './Navigation';


const Graduates = () => {
   return (
      <>
      <Navigation />
      <div>
         <img class="heroImageServices" src={image1} alt="horse"></img>
         <div class="mainTextBodyHeaderServicesMain">Ryebridge Stud Graduates
         <div class="horseInfoTitleGraduates">We're proud to have bred, raised and sold numerous top level thoroughbreds over the past decade. </div>
         </div>

         <div class="mainTextBodyHeaderServices">Retained by Ryebridge Stud</div>
         <div class="graduatesDivider" />

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Massini’s Trap</div>
               <div class="horseInfoTitle">2009 BAY GELDING | DR MASSINI | SPARROWS TRAP</div>
               <div class="graduatesText">
                  <ul>
                     <li>Rated 134 </li>
                     <li>1st - 2012 Maiden Hurdle - Ballinrobe</li>
                     <li>1st - 2013 Novice Hurdle - Listowel</li>
                     <li>1st - 2014 Handicap Flat - Ballinrobe</li>
                     <li>1st - 2014 Handicap Flat - Killarney</li>
                     <li>1st - 2016 Handicap Hurdle - Aintree</li>
                     <li>1st - 2018 Handicap Flat - Chepstow</li>
                     <li>2nd - 2012 Maiden Flat - Lepoardstown </li>
                     <li>2nd - 2013 Hurdle - Cork</li>
                     <li>2nd - 2014 Handicap Flat - Down Royal</li>
                     <li>2nd - 2016 Limestone Lad Grade 3 Hurdle - Naas</li>
                     <li>2nd - 2016 Handicap Hurdle - Haydock</li>
                     <li>3rd - 2013 Maiden Hurdle - Ballinrobe</li>
                     <li>3rd - 2013 Hurdle - Limerick</li>
                     <li>3rd - 2015 Ladbrokes Handicap Hurdle (Grade C) - Galway</li>
                     <li>3rd - 2015 Hurdle - Punchestown</li>
                     <li>3rd - 2016 Hurdle - Cork</li>
                     <li>3rd - 2016 Handicap Hurdle - Gowran Park</li>
                     <li>3rd - 2017 Handicap Hurdle - Aintree</li>
                     <li>3rd - 2018 Hurdle - Kilbeggan</li>
                     <li>3rd - 2018 Handicap Hurdle - Kelso</li>
                     <li>3rd - 2018 Handicap Flat - Catterick</li>

                  </ul>

               </div>
            </div>

            <div class="column">
               <img src={massini} width="100%" height="100%" alt="massini horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Bell-I-Am </div>
               <div class="horseInfoTitle">2018 Bay Filly. Belardo Ex. Adroit.</div>
               <div class="graduatesText">
                  <ul>
                     <li>Rated 83.</li>
                     <li>1st - 2020 Maiden Flat - Sligo</li>
                     <li>3rd - 2020 Ballyhane Blenheim Stakes - Fairyhouse </li>
                     <li>3rd - 2020 Maiden Flat - Bellewstown </li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={bellam} width="100%" height="100%" alt="bellam horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Himalayan Beauty </div>
               <div class="horseInfoTitle">2018 Bay Filly. The Gurkha Ex. Praskovia</div>
               <div class="graduatesText">
                  <ul>
                     <li>Rated 88.</li>
                     <li>1st - 2021 Handicap Flat - Gowran Park</li>
                     <li>2nd - 2021 Handicap Flat - Roscommon  </li>
                     <li>2nd - 2021 Handicap Flat - Gowran Park </li>
                     <li>2nd - 2021 Handicap Flat - Punchestown  </li>
                     <li>1st - 2022 Handicap Flat -  Limerick  </li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={himalayan} width="100%" height="100%" alt="himalayan horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Little Lady Lu</div>
               <div class="horseInfoTitle">2015 Chestnut Filly. Malher Ex. Sparrows Trap</div>
               <div class="graduatesText">
                  <ul>
                     <li>Rated 97.</li>
                     <li>1st - 2020 Maiden Hurdle - Kilbeggan</li>
                     <li>3rd - 2020 Maiden Hurdle - Kilbeggan	</li>
                     <li>3rd - 2020 Maiden Hurdle - Kilbeggan</li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={turbo} width="100%" height="100%" alt="horse" />
            </div>
         </div>

         <div class="mainTextBodyHeaderServices">Sold:</div>
         <div class="row">
            <div class="column">
               <div class="graduatesNames">James Boru</div>
               <div class="horseInfoTitle">Bred and Sold by Ryebridge Stud - Tattersalls Ireland, August National Hunt Sale 2017</div>
               <div class="graduatesText">
                  <ul>
                     <li>2013 Bay Gelding. Brian Boru Ex. Sparrows Trap</li>
                     <li>Rated 102.</li>
                     <li>1st - 2020 Handicap Hurdle - Down Royal</li>
                     <li>1st - 2018 Point to Point - Loughanmore</li>
                     <li>1st - 2018 Point to Point - Necarne</li>
                     <li>2nd - 2018 Point to Point - Castletown-Geoghegan</li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={jamesboru} width="100%" height="100%" alt="jamesboru horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Turbo Command</div>
               <div class="horseInfoTitle">Bred and Sold by Ryebridge Stud - Goffs November Foal Sale 2017</div>
               <div class="graduatesText">
                  <ul>
                     <li>2017 Grey Gelding. War Command Ex. The Tempest</li>
                     <li>Rated 80.</li>
                     <li>1st - 2021 Maiden Flat - Dundalk </li>
                     <li>1st - 2023 Handicap Flat - Southwell </li>
                     <li>2nd - 2019 Maiden Flat - Dundalk </li>
                     <li>2nd - 2019 Maiden Flat - Dundalk </li>
                     <li>2nd - 2020 Maiden Flat - Tipperary </li>
                     <li>3rd - 2020 Maiden Flat - Dundalk </li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={turbocommand} width="100%" height="100%" alt="turbocommand horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">King Arthur's Sword</div>
               <div class="horseInfoTitle">Bred and Sold by Ryebridge Stud - Goffs November Foal Sale 2017</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2017 Bay Gelding. Camelot Ex. Balakera</li>
                     <li>Rated 82.</li>
                     <li>1st - 2022 Handicap Flat - Lepoardstown</li>
                     <li>3rd - 2020 Maiden Flat - Naas</li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={kingarthur} width="100%" height="100%" alt="kingarthur horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Mythical Molly</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud - Goffs November Foal Sale 2019</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2019 Bay Filly. Profitable Ex. The Tempest.</li>
                     <li>Rated 82.</li>
                     <li>1st - 2021 Nursery - Musselburgh </li>
                     <li>2nd -  2021 Maiden - Newcastle </li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={mythicalmolly} width="100%" height="100%" alt="mythical molly horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Beaux Villages</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud - Goffs November Foal Sale 2018</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2018 Bay Filly. Gleneagles Ex. Balakera.</li>
                     <li>1st - 2021 Maiden - Chantilly </li>
                  </ul>
               </div>
            </div>

            <div class="column">
               <img src={beauxvillage} width="100%" height="100%" alt="mythical molly horse" />
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Celesial Star</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud - Goffs November Foal Sale 2019</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2019 Chestnut Filly. Ribchester Ex. Praskovia.</li>
                     <li>2nd - 2022 Maiden - Musselburgh </li>
                     <li>2nd - 2022 Maiden - Ayr </li>
                     <li>3rd - 2022 Handicap - Musselburgh </li>

                  </ul>
               </div>
            </div>
         </div>

         <div class="row">
            <div class="column">
               <div class="graduatesNames">Coralillo</div>
               <div class="horseInfoTitle">Bred in partnership by Ryebridge Stud</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2020 Bay Filly. Havana Gray Ex. Lady Nancy.</li>
                     <li>1st - 2022 Maiden - Fairyhouse </li>
                     <li>3rd - 2022 Group 3 - Curragh </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="column">
               <div class="graduatesNames">Reidh - Goffs November Foal Sale 2020</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2020 Bay Colt. Kodiac Ex. Praskovia</li>
                     <li> 3rd - 2022 Novice Stakes - Ayr </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="column">
               <div class="graduatesNames">Wujdaan - Goffs November Foal Sale 2020</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2020 Bay Filly. Gleneagles Ex. The Tempest</li>
                     <li> 2nd - 2022 Maiden - Roscommon </li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="column">
               <div class="graduatesNames">Happy Won - Goffs November Foal Sale 2019</div>
               <div class="horseInfoTitle">Bred and sold by Ryebridge Stud</div>
               <div class="graduatesText">
                  <ul>
                     <li> 2019 Bay Gelding. Holy Roman Emperor Ex. Vibe Queen</li>
                     <li> 2nd - 2022 The Pearce Memorial Challenge Cup - Sha Tin (HK) </li>
                     <li> 3rd - 2022 Tung Wan Shan plate - Sha Tin (HK)</li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      </>
   );
};

export default Graduates;