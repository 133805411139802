import React from 'react';

import image1 from '../assets/hero-contact.jpg';
import image2 from '../assets/body-contact.jpg';

import Navigation from './Navigation';

const Contact = () => {
    return (
        <>
        <Navigation />
        <div>
            <a href="https://www.google.com/maps/dir//Ryebridge+Stud,+R158,+Co.+Meath/@53.4496704,-6.7021614,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x486765e6ee05fdf1:0x4a01a24a52d9f456!2m2!1d-6.7082892!2d53.4394119" className="google maps directions to ryebridge">
                <img className="heroImageServices" src={image1} alt="horse"></img>
            </a>
            <div className="mainTextBodyHeaderServices">Contact Ryebridge Stud</div>
            <div className="row">
                <div className="column">
                    <div className="horseInfoTitle">ADDRESS</div>
                    <div className="horseInfoText">
                        Ryebridge Stud,<br></br>
                    Summerhill Road,<br></br>
                    Kilcock,<br></br>
                    Co Meath, Ireland<br></br>
                    W23R97Y</div>
                    <div className="horseInfoTitle">EMAIL</div>
                    <div className="horseInfoText">info@ryebridgestud.com</div>
                    <div className="horseInfoTitle">PHONE</div>
                    <div className="horseInfoText">
                        <a href="tel:+ 00353 874578903">+ 00353 874578903 - Sebastian Curran</a><br></br>
                        <a href="tel:+ 00353 879700957">+ 00353 879700957 - Thea Curran</a>
                    </div>
                    <div className="horseInfoTitle">NEARBY</div>
                    <div className="horseInfoText">
                    <ul>
                        <li>30 minutes from Dublin Airport</li>
                        <li>40 minutes from Dublin Port</li>
                        <li>40 minutes from Goffs</li>
                        <li>40 minutes from Tattersalls, Ireland</li>
                        <li>40 minutes from The Curragh racecourse</li>
                        </ul>
                </div>
                </div>

                <div className="column">

                    <img src={image2} width="100%" height="100%" alt="horse" />
                </div>
            </div>
        </div>
        </>
    );
}

export default Contact;