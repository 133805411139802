import React from 'react';
import "../css/Services.css";

import image1 from '../assets/bitching.jpeg';
import image2 from '../assets/bitching2.jpeg';
import Navigation from './Navigation';

const Services = () => {
   return (
      <>
      <Navigation />
      <div>
         <img class="heroImageServices" src={image1} alt="horse"></img>
         <div class="mainTextBodyHeaderServices">Ryebridge Bitching Team</div>
         <div class="row">
            <div class="column">
               <div class="horseInfoTitle">Mr No Gainz</div>
               <div class="horseInfoText">Hasnt been lifting in awhile very noticable might need to start lifting again ? Bitching haircut</div>
            </div>
            <div class="column">
               <img src={image2} width="100%" height="100%" alt="horse" />
            </div>
            <div class="column">
               <div class="horseInfoTitle">Thea Curran</div>
               <div class="horseInfoText">Probably could outlift mr no gainz</div>
            </div>
            <div class="column">
               <img src={image2} width="100%" height="100%" alt="horse" />
            </div>
            <div class="column">
               <div class="horseInfoTitle">Wesley Curran</div>
               <div class="horseInfoText">Probably could outlift mr no gainz</div>
            </div>
            <div class="column">
               <img src={image2} width="100%" height="100%" alt="horse" />
            </div>
            <div class="column">
               <div class="horseInfoTitle">Lene Curran</div>
               <div class="horseInfoText">Probably could outlift mr no gainz</div>
            </div>
            <div class="column">
               <img src={image2} width="100%" height="100%" alt="horse" />
            </div>
         </div>
      </div>
      </>
   );
}

export default Services;