import React from 'react';

import { HashLink } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom';
import image1 from '../assets/wedding-logo.svg';
import "../css/Header.css";
import "../css/fonts.css";


const NavigationWedding = () => {
    return (
        <div class="header weddingOverwrite">
            <a href="/" class="weddingLogo">
                <img src={image1} alt="wedding logo" />
            </a>
            <div class="header-right">
                <HashLink class="linkHeader weddinLink" to='/Wedding#the-wedding-day'>The Wedding Day</HashLink>
                <HashLink class="linkHeader weddinLink" to='/Wedding#dress-code'>Dress Code</HashLink>
                <HashLink class="linkHeader weddinLink" to='/Wedding#travel-stay'>Travel & Stay</HashLink>
                <a class="linkHeader buttonWedding" href="https://forms.gle/JdL9XfU1fMrDRvVe7" target="noopener noreferrer">RSVP</a>

            </div>
        </div>

    );
}

export default NavigationWedding;


